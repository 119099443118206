import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layouts/Layout';
import Auth from './containers/Auth/Auth';
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword'
import Home from './components/Home/Home';
import Orders from './components/Orders/Orders';
import Sidebar from './components/Sidebar/Sidebar'
import RequestQuote from './components/Home/RequestQuote/RequestQuote';
import PriceIndication from './components/Home/PriceIndication//PriceIndication';
import OrderStatus from './components/OrderStatus/OrderStatus';
import OrderSummary from './components/Home/OrderSummary/OrderSummary';
import CreateBaseOrder from './components/Home/CreateBaseOrder/CreateBaseOrder';
import CreateNote from './components/Home/CreateNote/CreateNote';
import AdminLanding from './components/Home/AdminLanding/AdminLanding'
import Users from './components/Users/Users';
// import prc from './components/OrderStatus/PriceQuote/PriceQuote'


function App() {
  return (
    // <div>

    <Layout>
      <Switch>
        <Route path='/home' exact component={Home} />
        <Route path='/home/request' component={RequestQuote} />
        <Route path='/home/price' component={PriceIndication} />
        <Route path='/home/checkout' component={OrderSummary} />
        <Route path='/home/create-baseorder' component={CreateBaseOrder} />
        <Route path='/home/create-note' component={CreateNote} />
        <Route path='/select-user' component={AdminLanding} />
        <Route path='/orders' component={Orders} />
        <Route path='/users' component={Users} />
        <Route path='/summary' component={OrderSummary} />
        <Route path='/forgot-password' component={ForgotPassword}></Route>
        <Route path='/' exact component={Auth} />
      </Switch>
    </Layout>
    // </div>
  );
}

export default App;
